<template>
  <v-container fluid>
    <div class="main">
      <v-row v-if="loading">
        <v-col cols="12">
          <v-card class="rounded-lg" flat>
            <v-skeleton-loader type="table"></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12">
          <v-card class="rounded-lg shadow">
            <v-card-title>
              <form @submit.prevent="fetchData">
                <v-text-field
                    v-model="filter.keyword"
                    append-icon="mdi-magnify"
                    clearable
                    dense
                    filled
                    hide-details
                    placeholder="Rechercher..."
                    rounded
                    single-line
                />
              </form>

              <v-spacer/>

              <v-btn text
                  @click="refresh"
                  class="grey--text"
              >
                <v-icon left>mdi-refresh</v-icon>
                Mettre à jour
              </v-btn>

              <FilterDialog :filter="filter" @filter="filterData($event)"/>

            </v-card-title>
            <v-divider/>
            <v-card-text class="pa-0">
              <div v-if="requests.data.length > 0">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>ID</th>
                      <th>Utilisateur</th>
                      <th>Balance</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Telephone</th>
                      <th>Message</th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in requests.data" :key="item.id">
                      <td>
                        {{ item.id }}
                      </td>
                      <td style="cursor: pointer;" @click="showAccount(item?.user?.account?.id)">
                        <div class="text-no-wrap font-weight-medium">
                          <v-avatar size="35">
                            <v-img
                                v-if="item.user?.account?.photo"
                                :src="fileUrl + item.user?.account?.photo"
                            />
                            <v-img
                                v-else
                                :src="require('@/assets/avatar.png')"
                            />
                          </v-avatar>
                          {{ item.user ? item.user.name : "-" }}
                        </div>
                      </td>
                      <td class="text-no-wrap font-weight-medium gifty--text">
                        {{ CurrencyFormatting(item.user?.account?.all_balance) }}
                      </td>
                      <td class="text-no-wrap">
                        {{ moment(item.created_at).format('DD/MM/YYYY hh:mm') }}
                      </td>
                      <td>
                        <v-chip :color="$states.colors[item.status.toUpperCase()]" small>
                          {{ $states.texts[item.status.toUpperCase()] }}
                        </v-chip>
                      </td>
                      <td>
                        {{ item.user ? item.user.phone : "-" }}
                      </td>
                      <td>
                        {{ item.message }}
                      </td>
                      <td>
                        <v-menu transition="slide-x-transition" bottom right
                                v-if="item.status == 'pending'">

                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list dense>
                            <v-list-item @click="handleRefuse(item)">
                              <v-list-item-icon>
                                <v-icon>mdi-account-off</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Refuser</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="handleDelete(item)">
                              <v-list-item-icon>
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Supprimer le compte</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-divider/>

                <div class="d-flex justify-space-between pl-4 pt-6 pr-4">

                  <div :style="{ width: '120px' }">
                    <v-select outlined
                              label="Lignes par page"
                              v-model="filter.size"
                              dense
                              :items="[5, 10, 20, 30, 40, 50]"
                              @change="fetchData"></v-select>
                  </div>

                  <v-spacer/>

                  <v-pagination total-visible="6" v-if="requests.total > 15" circle v-model="filter.page"
                                :length="Math.ceil(requests.total / filter.size)" @input="fetchData"></v-pagination>

                </div>
              </div>

              <div v-else class="text-center">
                <v-avatar tile size="200">
                  <v-img :src="require('@/assets/database.svg')"></v-img>
                </v-avatar>
                <p class="mb-5">Aucun enregistrement</p>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <ConfirmDeleteUser ref="confirmDeleteUser" @refresh="fetchData"/>
      <ConfirmRefuseDeleteUser ref="confirmRefuseDeleteUser" @refresh="fetchData"/>

    </div>
  </v-container>
</template>

<script>
import {HTTP} from '@/http-common';

import ConfirmDeleteUser from './disable_requests/ConfirmDeleteUser.vue';
import ConfirmRefuseDeleteUser from './disable_requests/ConfirmRefuseDeleteUser.vue';
import FilterDialog from "./disable_requests/FilterDialog.vue";

export default {
  components: {ConfirmDeleteUser, ConfirmRefuseDeleteUser, FilterDialog},
  data() {
    return {
      requests: {},
      loading: true,
      filter: {
        page: 1,
        size: 10
      },
      fileUrl: process.env.VUE_APP_FILE_URL,
    }
  },
  methods: {
    fetchData() {
      this.loading = true;
      HTTP.get("v1/users/disable_requests", {
        params: {
          ...this.filter
        }
      }).then((res) => {
        this.requests = res.data.data;
        this.loading = false;

      }).catch((e) => {
        this.loading = false;
        console.log(e);
      });
    },
    handleDelete(item) {
      this.$refs.confirmDeleteUser.open(item);
    },
    handleRefuse(item) {
      this.$refs.confirmRefuseDeleteUser.open(item);
    },
    showAccount(account_id) {
      if (!account_id) return;
      let route = this.$router.resolve({path: "/users/" + account_id});
      window.open(route.href, "_blank");
    },

    filterData(filter) {
      this.fetchData();
    },
    refresh() {
      this.filter = {
        page: 1,
        size: 10
      };

      this.fetchData();
    }
  },
  mounted() {
    this.fetchData();
  }
}
</script>

<style scoped></style>